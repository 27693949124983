<template>
  <StarRating
    v-model:rating="localRating"
    :star-size="starSize"
    :increment="0.01"
    :read-only="readOnly"
    :show-rating="false"
    :animate="animate"
    :fixed-points="2"
    @update:rating="onChangeRating"
  ></StarRating>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "my-rating",
  props: {
    rating: { type: Number, default: 0 },
    starSize: { type: Number, default: 25 },
    readOnly: { type: Boolean, default: false },
    animate: { type: Boolean, default: true },
  },
  components: {
    StarRating,
  },
  methods: {
    onChangeRating(rating) {
      this.$emit("onRate", rating);
    },
  },
  data() {
    return {
      localRating: this.rating,
    };
  },
};
</script>
