
import {computed, defineComponent, nextTick, onMounted, ref} from "vue";

import {
    setCurrentPageBreadcrumbs,
    setCurrentPageTitle,
} from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

import {Actions} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";
import {ErrorMessage, Field, Form} from "vee-validate";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import MyRate from "@/components/my-components/my-rate.vue";
import {useToast} from "vue-toastification";
import {reinitializeComponents} from "@/core/plugins/keenthemes";

export default defineComponent({
    name: "requests",
    components: {
        MyRate,
        ErrorMessage,
        Field,
        Form,
        ExclamationNotice,
    },
    setup() {
        const store = useStore();
        const toast = useToast();

        let in_progress = ref(false);

        const new_request = Yup.object().shape({
            req_type: Yup.string().required("Виберіть тип заявки"),
            req_short_description: Yup.string(),
        });

        const new_note_to_request = Yup.object().shape({
            short_note:
                Yup.string().required(
                    "Обов'язкове поле"
                ) /*.min(20, 'Мінімальна кількість символів не менше 20'),*/,
        });

        const userData = computed(() => {
            let user = store.getters.currentUser;

            if (user && user.user) {
                store.dispatch(Actions.GET_USER_REQUESTS_DATA, {
                    login: user.user.login,
                    token: JwtService.getToken(),
                });
            }

            return user;
        });

        const onRateWork = async (rate) => {
            await store.dispatch(Actions.SET_REQUEST_RATING, {
                token: JwtService.getToken(),
                login: userData.value.user.login,
                request_id: requestsData.value.active_request.id,
                worker_id: requestsData.value.active_request.worker_id,
                rating: rate,
                fn: (result) => {
                    result.success
                        ? toast.success(result.message)
                        : toast.error(result.message);
                },
            });
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs("Заявки", ["Profile", "Tariffs"]);

            setCurrentPageTitle("Заявки");

            nextTick(() => {
                reinitializeComponents();
            });
        });

        const onCancelRequest = async () => {
            Swal.fire({
                text: "Скасувати виконання заявки ?",
                title: "Скасування заявки",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.CANCEL_REQUEST, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        group_id: userData.value.user.group_id,
                        request_id: requestsData.value.active_request.id,
                        phones: userData.value.settings.settings.phone,
                        address: userData.value.user.address,
                        service_type: 2,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        };

        const addNewNoteToRequest = async (values) => {
            Swal.fire({
                text: "Додати короткий відгук про виконання заявки ?",
                title: "Додавання відгуку",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.ADD_NEW_NOTE_TO_REQUEST, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        note: values.short_note,
                        request_id: requestsData.value.active_request.id,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        };

        const addNewRequest = async (values) => {
            Swal.fire({
                text: "Додати заявку ?",
                title: "Нова заявка",
                icon: "question",
                buttonsStyling: false,
                confirmButtonText: "Так",
                showCancelButton: true,
                cancelButtonText: "Скасувати",
                customClass: {
                    confirmButton: "btn fw-bold btn-light-success",
                    cancelButton: "btn fw-bold btn-light-danger",
                },
            }).then((value) => {
                if (value.isConfirmed) {
                    in_progress.value = true;

                    store.dispatch(Actions.ADD_NEW_REQUEST, {
                        token: JwtService.getToken(),
                        login: userData.value.user.login,
                        req_type: values.req_type,
                        req_short_description: values.req_short_description,
                        group_id: userData.value.user.group_id,
                        phones: userData.value.settings.settings.phone,
                        address: userData.value.user.address,
                        service_type: 2,
                        fn: (result) => {
                            result.success ? toast.success(result.message) : toast.error(result.message);

                            in_progress.value = false;
                        },
                    });
                }
            });
        };

        const requestsData = computed(() => {
            return store.getters.getUserRequestsData;
        });

        return {
            userData,
            requestsData,

            //Request
            addNewRequest,
            onCancelRequest,
            addNewNoteToRequest,

            //Forms
            new_request,
            new_note_to_request,

            in_progress,
            onRateWork,
        };
    },
});
